import styled from "styled-components";

const Wrapper = styled.div``;

function SliderY() {
  return <Wrapper>
    
  </Wrapper>;
}
export default SliderY;
